function isValidDate(input) {
  if (!input) {
    return { isValid: false, inputDate: "" };
  }
  const inputDate = typeof input === "object" ? input : new Date(input);
  return { isValid: !isNaN(inputDate?.getTime()), inputDate };
}

export default () => {
  function getFormattedDateStringNoTime(input) {
    const { isValid, inputDate } = isValidDate(input);
    if (!isValid) {
      return "";
    }
    return inputDate.toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  function checkValidDate(input) {
    const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
    return date_regex.test(input);
  }

  // Month XX, XXXX
  function getLongFormattedDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.toLocaleString("default", {
      month: "long",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  }

  return { getFormattedDateStringNoTime, checkValidDate, getLongFormattedDate };
};
