import { defineStore } from "pinia";
import { STRUCTURE_ENDPOINT } from "@/constants/Endpoints";
import { LOGGER } from "@/util/logger";
import axios from "axios";
import { ref } from "vue";
import router from "@/router";
import { OTHER_LINKS_PAGE_PARAMS } from "@/constants/OtherLinks";

export const useQuickSearchStore = defineStore("quickSearch", () => {
  let structure = ref({
    BRKEY: "",
    BMSID: "",
    REPORTGROUP: "",
  });
  let errorMessage = ref(false);

  const getStructure = () => {
    return structure.value;
  };
  const setStructure = (value) => {
    structure.value = value;
  };
  const getErrorMessage = () => {
    return errorMessage.value;
  };
  const setErrorMessage = (value) => {
    errorMessage.value = value;
  };

  const resetStructure = () => {
    structure.value = {
      BRKEY: "",
      BMSID: "",
      REPORTGROUP: "",
    };
  };

  const redirectUserToStructureSearch = (e, id) => {
    router.push({
      name: "OtherLinks",
      params: {
        pageRoute: OTHER_LINKS_PAGE_PARAMS.SEARCH_STRUCTURE,
      },
      query: { bmsId: id, path: router.currentRoute.value.path },
    });
  };

  const getStructureMetaDataByBMSID = async () => {
    let url = STRUCTURE_ENDPOINT.GET_STRUCTURE_META_DATA_BY_BMSID.replace(
      "{bmsId}",
      structure.value.BMSID
    );
    return await axios
      .get(url)
      .then((response) => {
        structure.value.REPORTGROUP = response.data.data.reportGroup;
        structure.value.BRKEY = response.data.data.brkey;
      })
      .catch((e) => {
        LOGGER.logException(e);
        structure.value.REPORTGROUP = "";
        structure.value.BRKEY = "";
        if (e?.response?.status === 412) {
          redirectUserToStructureSearch(e, structure.value.BMSID);
          throw new Error(
            `Multiple BRKEYs found for BMSID-${structure.value.BMSID}`
          );
        } else {
          errorMessage.value = true;
        }
      });
  };

  const getStructureMetaDataByBRKEY = async () => {
    let url = STRUCTURE_ENDPOINT.GET_STRUCTURE_META_DATA_BY_BRKEY.replace(
      "{brkey}",
      structure.value.BRKEY
    );
    return await axios
      .get(url)
      .then((response) => {
        structure.value.REPORTGROUP = response.data.data.reportGroup;
        structure.value.BMSID = response.data.data.bmsId;
      })
      .catch((e) => {
        structure.value.REPORTGROUP = "";
        structure.value.BMSID = "";
        errorMessage.value = true;
        LOGGER.logException(e);
      });
  };

  return {
    resetStructure,
    getErrorMessage,
    setErrorMessage,
    getStructureMetaDataByBRKEY,
    getStructureMetaDataByBMSID,
    getStructure,
    setStructure,
  };
});
