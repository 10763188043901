export const ACCESS_LEVELS = {
  VIEW: "view",
  EDIT: "edit",
  EXPORT: "export",
  ADD: "add",
  DRAFT: "draft",
  DELETE: "delete",
  CUSTOM: {
    BPR_CRITICAL_FINDING: "bprCriticalFinding"
  }
};
