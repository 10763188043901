<template>
  <v-container fluid class="blankSpace" id="landingPage_container">
    <v-row>
      <v-col class="px-0 pt-0" cols="12">
        <div
          v-if="external"
          class="withBlueBackground px-2"
          id="landingPage_publicWelcomeMessage"
        >
          Welcome to the public facing landing page of the PennDOT Bridge
          Management System 3 (BMS3). Authorized users may access the login page
          to access the site to view and maintain inventory and inspection
          information for Pennsylvania bridges and other structures. BMS3 PWA
          supports decision-making to help maintain the long-term health of
          bridges and to formulate optimal programs for bridge maintenance and
          rehabilitation.
        </div>
        <div
          v-else
          class="withBlueBackground px-2"
          id="landingPage_welcomeMessage"
        >
          Welcome to the progressive web application of the PennDOT Bridge
          Management System 3 (BMS3). Authorized users may access this site to
          view and maintain inventory and inspection information for
          Pennsylvania bridges and other structures. BMS3 PWA supports
          decision-making to help maintain the long-term health of bridges and
          to formulate optimal programs for bridge maintenance and
          rehabilitation.
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!external" justify="end" class="withRowBackground mb-2">
      <v-col class="px-0 alignCenter" cols="4">
        <h3
          class="align-self-center whiteFont"
          id="landingPage_quickSearchHeader"
        >
          Quick Search
        </h3>
      </v-col>
      <v-col class="pr-6 alignRight" cols="4">
        <v-btn
          class="align-self-center landingPageButton"
          id="btn_goToStructureSearch"
          variant="outlined"
          @click="goToStructureSearch()"
        >
          Search Screen
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!external">
      <v-col cols="5" sm="5" md="4" lg="4" xl="3" class="d-flex pl-2 pb-0 pt-2">
        <label class="pt-1 pr-2">BRKEY:</label>
        <v-text-field
          class="pr-2"
          id="input_searchBrkey"
          v-model="brKey"
          autofocus
          single-line
          v-maska:[MASK.NUMBER]
          clearable
          @keyup.enter="enterKeyHandlerForBRKey"
        ></v-text-field>
        <v-btn
          id="btn_retrieveBrkey"
          variant="outlined"
          @click="readDataFromAPIBrkey()"
        >
          GO
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1" md="3" lg="3" xl="5" class="d-flex pb-0 pt-2" />
      <v-col cols="5" md="4" lg="4" xl="3" class="d-flex pl-2 pb-0 pt-2">
        <label class="pt-1 pr-2">BMS ID:</label>
        <v-text-field
          class="pr-2"
          id="input_searchBmsId"
          v-model="bmsId"
          autofocus
          v-maska:[MASK.NUMBER]
          single-line
          clearable
          @keyup.enter="enterKeyHandlerForBMSId"
        ></v-text-field>
        <v-btn
          id="btn_retrieveBmsId"
          variant="outlined"
          @click="readDataFromAPIBmsid()"
        >
          GO
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-0 pt-0" cols="12">
        <h3
          class="withBackground py-1 px-2"
          id="landingPage_penndotLinksHeader"
        >
          PennDOT Links
        </h3>
      </v-col>
      <v-col>
        <PenndotLinks id="externalPenndotLinks" :isExternal="external" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-0" cols="12">
        <h3
          class="withBackground py-1 px-2"
          id="landingPage_bulletinBoardHeader"
        >
          Bulletin Board
        </h3>
      </v-col>
      <v-col>
        <ExternalBulletinBoard id="externalBulletinBoard" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-0" cols="12">
        <a
          id="link_penndotPrivacyPolicy"
          class="ml-2"
          style="margin-right: auto; max-width: 60%"
          href="https://www.pa.gov/privacy-policy/"
          title="https://www.pa.gov/privacy-policy/"
        >
          <span style="font-size: small"> PENNDOT Privacy Policy </span>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExternalBulletinBoard from "@/components/shared/ExternalBulletinBoard.vue";
import { useQuickSearchStore } from "@/stores/quickSearch";
import router from "@/router";
import { INVENTORY_LINKS_PARAMS } from "@/constants/InventoryLinks";
import PenndotLinks from "@/components/shared/PenndotLinks.vue";
import { ref, nextTick } from "vue";
import { MASK } from "@/constants/Maskas";
import { OTHER_LINKS_PAGE_PARAMS } from "@/constants/OtherLinks";
import { LOGGER } from "@/util/logger";

export default {
  name: "LandingPage",
  props: {
    external: { type: Boolean, default: false },
  },
  setup() {
    let brKey = ref(null);
    let bmsId = ref(null);
    const quickSearchStore = useQuickSearchStore();

    const readDataFromAPIBrkey = async () => {
      if (brKey.value) {
        quickSearchStore.setStructure({
          BRKEY: brKey.value,
          BMSID: "",
          REPORTGROUP: "",
        });
        await quickSearchStore.getStructureMetaDataByBRKEY();
        router.push({
          name: "InventoryLinks",
          params: {
            pageRoute: INVENTORY_LINKS_PARAMS.STRUCTURE_IDENTIFICATION,
          },
        });
      }
    };
    const readDataFromAPIBmsid = async () => {
      if (bmsId.value) {
        quickSearchStore.setStructure({
          BRKEY: "",
          BMSID: bmsId.value,
          REPORTGROUP: "",
        });
        try {
          await quickSearchStore.getStructureMetaDataByBMSID();
          router.push({
            name: "InventoryLinks",
            params: {
              pageRoute: INVENTORY_LINKS_PARAMS.STRUCTURE_IDENTIFICATION,
            },
          });
        } catch (e) {
          LOGGER.logException(e);
        }
      }
    };
    const goToStructureSearch = () => {
      router.push({
        name: "OtherLinks",
        params: {
          pageRoute: OTHER_LINKS_PAGE_PARAMS.SEARCH_STRUCTURE,
        },
      });
    };

    const enterKeyHandlerForBRKey = () => {
      nextTick(() => document.getElementById("btn_retrieveBrkey").focus());
      document.getElementById("btn_retrieveBrkey").click();
    };

    const enterKeyHandlerForBMSId = () => {
      nextTick(() => document.getElementById("btn_retrieveBmsId").focus());
      document.getElementById("btn_retrieveBmsId").click();
    };
    return {
      enterKeyHandlerForBRKey,
      enterKeyHandlerForBMSId,
      goToStructureSearch,
      MASK,
      brKey,
      bmsId,
      readDataFromAPIBrkey,
      readDataFromAPIBmsid,
    };
  },
  components: { ExternalBulletinBoard, PenndotLinks },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;

.withBlueBackground {
  background: c.$p-pa-blue;
  color: c.$p-white;
}
.landingPageButton {
  background-color: c.$p-white;
  color: c.$p-black;

  button:focus {
    outline: c.$p-link-blue-focus solid 5px;
  }

  button:hover {
    background-color: c.$p-pa-blue;
    color: c.$p-white;
  }
}
.withRowBackground {
  background-color: c.$p-pa-light-blue;
}
.alignRight {
  text-align: right;
}
.whiteFont {
  color: c.$p-white;
}
</style>
