export const OTHER_LINKS_PAGES = {
  BULLETIN_BOARD: "Bulletin Board",
  LINK_MAINTENANCE: "Link Maintenance",
  BRIDGE_PROBLEM_REPORT: "Bridge Problem Report",
  BP_ASSIGNMENT: "BP Assignment",
  SEARCH_STRUCTURE: "Structure Search",
  REPORTS: "Reports",
  RISK_SCORE_SEARCH: "Risk Score Search",
};
export const OTHER_LINKS_PAGE_PARAMS = {
  BULLETIN_BOARD: "bulletinBoard",
  LINK_MAINTENANCE: "linkMaintenance",
  BRIDGE_PROBLEM_REPORT: "bridgeProblemReport",
  BP_ASSIGNMENT: "bpAssignment",
  SEARCH_STRUCTURE: "structureSearch",
  REPORTS: "reports",
  RISK_SCORE_SEARCH: "riskScoreSearch",
};
export const ACCEPTABLE_FILE_TYPES = ["jpg", "tiff", "png"];
export const PHOTO = {
  SELECT_FROM_ECS: "uploadFromEcs",
  UPLOAD_FROM_PC: "uploadFromPc",
};

export const DOCUMENT_TYPES = {
  INSPECTION_PHOTO: 66,
};

export const OTHER_LINKS_ROUTES = {
  BRIDGE_PROBLEM_REPORTS_ROUTE: "/otherLinks/bridgeProblemReport",
  BP_ASSIGNMENT_ROUTE: "/otherLinks/bpAssignment",
};

export const BPR_MESSAGES = {
  BPR_DELETE_CONFIRM:
    "Are you sure you want to delete this Bridge Problem Report?",
};
